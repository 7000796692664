import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Wrapper, Divider, P } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { screenMax } from '@farewill/ui/helpers/responsive'
import PATHS from 'paths'
import {
  TRUSTPILOT_RATING_OUT_OF_5,
  TRUSTPILOT_REVIEWS_TOTAL,
  WILLS_SIGN_UP_PATH,
  TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_PRICE_IN_POUNDS,
  FF_TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_ENABLED,
} from 'config'
import { useRecordGAPageview } from 'lib/hooks/useRecordGAPageview'
import PartnerLandingPageLayout from 'layouts/PartnerLandingPageLayout'
import { PRODUCTS } from 'lib/products/constants'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'

import WrapperWithEdge from 'components/WrapperWithEdge'
import TrustpilotReviews from 'components/TrustpilotWidget/TrustpilotReviews'
import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions'
import Guides from 'components/Guides'
import LinkButton from 'components/LinkButton'
import AwardBanner from 'components/AwardBanner'
import { PartnerCustomMessage } from 'components/PartnerCustomMessage'
import Charities from 'views/wills/components/Charities'
import Hero from 'views/wills/components/Hero'
import UpdateWill from 'views/wills/components/UpdateWill'
import Support from 'views/wills/components/Overview/Support'
import Steps from 'views/wills/components/Overview/Steps'
import Privacy from 'views/wills/components/Overview/Privacy'
import TelephoneSection from 'views/wills/components/Overview/TelephoneSection'
import Questions from 'views/wills/components/Overview/Questions'
import GetStarted from 'views/wills/components/Overview/GetStarted'

import { GUIDES_COPY } from 'views/wills/components/Overview/constants'
import { FREE_WILLS_DEFAULT_TEXT } from '../constants'

const StyledPartnerCustomMessageWrapper = styled(Wrapper)`
  ${screenMax.s`
 display: none;
`}
`

interface PartnerOnlineAndTelephoneProps {
  data: {
    page: {
      utmCampaign: string
      utmMedium: string
      bannerText: { bannerText: string }
      partner: {
        bannerBackgroundColor: string
        bannerLogoAlignment: 'left' | 'right'
        bannerText: { bannerText: string }
        bannerTextColor: string
        logo: { file: { url: string }; title: string }
        utmSource: string
        utmChannel: string
        telephoneWillsEnabled: boolean
        hideFarewillLogoInHeader: boolean | null
        partnerId: string
        offersDiscountedWills: boolean
        partnerCustomMessage: { partnerCustomMessage: string | null }
        partnerDisplayName: string | null
      }
      termsAndConditions: { termsAndConditions: string } | null
      heroTitle: string | null
      heroSubtitle: { heroSubtitle: string } | null
      heroCtaText: string | null
    }
    faqSection: {
      title: GatsbyTypes.ContentfulFaqSection['title']
      questions: GatsbyTypes.ContentfulFaqSection['questions']
      relatedGuide?: GatsbyTypes.ContentfulFaqSection['relatedGuide']
    }
  }
}

const PartnerOnlineAndTelephone = ({
  data: {
    page: {
      bannerText,
      utmCampaign,
      utmMedium,
      partner,
      termsAndConditions,
      heroTitle,
      heroSubtitle,
      heroCtaText,
    },
    faqSection,
  },
}: PartnerOnlineAndTelephoneProps): React.ReactElement => {
  useRecordGAPageview('PartnerLandingPageView', {
    partner: partner.utmSource,
  })

  const partnerCustomMessage =
    partner.partnerCustomMessage?.partnerCustomMessage
  const partnerDisplayName = partner.partnerDisplayName || partner.utmSource

  /** The bannerText, heroTitle, heroSubtitle and heroCtaText are defined on the Partner Campaign in Contentful.
   * Note that they can be null, but we are not worried about this as we have default values defined in the
   * child components these values are passed to.
   *
   * In this component, if the partner is a free wills partner (!partner.offersDiscountedWills), then we want to
   * use values from the FREE_WILLS_DEFAULT_TEXT constant, rather than whatever is defined on the Partner Campaign
   * page.
   */
  const bannerTextValue = partner.offersDiscountedWills
    ? bannerText?.bannerText
    : FREE_WILLS_DEFAULT_TEXT.BANNER

  const heroTitleValue =
    (partner.offersDiscountedWills
      ? heroTitle
      : FREE_WILLS_DEFAULT_TEXT.HERO_TITLE) || undefined

  const heroSubtitleValue =
    (partner.offersDiscountedWills
      ? heroSubtitle?.heroSubtitle
      : FREE_WILLS_DEFAULT_TEXT.HERO_SUBTITLE) || undefined

  const ctaTextValue =
    (partner.offersDiscountedWills ? heroCtaText : undefined) || undefined

  return (
    <PartnerLandingPageLayout
      bannerText={bannerTextValue}
      title="Will Writing Service | Legal Will in 15 minutes"
      description={`Write a will from the comfort of your own home with the UK’s number one will specialists. Rated ${TRUSTPILOT_RATING_OUT_OF_5} out of 5 by over ${roundDownToNearestHundred(
        TRUSTPILOT_REVIEWS_TOTAL
      )} customers on Trustpilot.`}
      product={PRODUCTS.WILLS}
      canonicalPath={PATHS.WILLS.OVERVIEW}
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
      utmCampaign={utmCampaign}
      utmMedium={utmMedium}
      partner={partner}
      termsAndConditions={termsAndConditions}
    >
      <Wrapper background={COLOR.WHITE}>
        <Wrapper container>
          <Hero
            isPartnerCampaignPage
            title={heroTitleValue}
            subtitle={heroSubtitleValue}
            ctaText={ctaTextValue}
            preselectedService="online"
            telephoneWillsEnabled={partner.telephoneWillsEnabled}
            partnerCustomMessage={partnerCustomMessage}
            partnerDisplayName={partnerDisplayName}
          />
        </Wrapper>

        {partnerCustomMessage ? (
          <StyledPartnerCustomMessageWrapper
            container
            containerVerticalPadding={0}
          >
            <PartnerCustomMessage
              partnerCustomMessage={partnerCustomMessage}
              partnerDisplayName={partnerDisplayName}
            />
          </StyledPartnerCustomMessageWrapper>
        ) : (
          <Divider container />
        )}

        <TelephoneSection title="You can also make a will over the phone">
          <P>
            There’s no such thing as a one-size-fits-all will. So if your needs
            are more complex, you may prefer to speak to one of our will
            specialists. They’ll talk through your situation to find what works
            for you.
          </P>
          {FF_TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_ENABLED && (
            <P>
              If you need to, you can also add unlimited specialist clauses at a
              fixed additional cost of £
              {TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_PRICE_IN_POUNDS}.
            </P>
          )}
        </TelephoneSection>

        <Divider container />

        <Wrapper container containerPaddingBottom="XL">
          <TrustpilotReviews showTitle />
        </Wrapper>

        <Divider />
        <WrapperWithEdge edgeBackgroundColor={COLOR.WHITE} direction="down">
          <AwardBanner />
        </WrapperWithEdge>

        <Wrapper background={COLOR.WHITE} container>
          <Support />
        </Wrapper>
      </Wrapper>
      <Divider />

      <Steps />

      <Wrapper
        container
        containerPaddingTop="M"
        containerPaddingBottom="XL"
        centered
      >
        <LinkButton
          productLink
          to={WILLS_SIGN_UP_PATH}
          preselectedService="online"
          partnerCampaignReferral
          telephoneWillsEnabled={partner.telephoneWillsEnabled}
        >
          Let’s get started
        </LinkButton>
      </Wrapper>

      <Divider container />

      <UpdateWill illustration="illustrations/cropped/blob-with-laptop-and-cat" />

      <Divider container />

      <TelephoneSection title="Make a will over the phone">
        <>
          <P>
            Book a 15 min call with one of our will specialists. They’ll call
            you to chat about your situation and help you find the right service
            for&nbsp;you.
          </P>
          <P>
            You’ll need an email address to book the call online. Or you can
            call us during opening&nbsp;hours.
          </P>
        </>
      </TelephoneSection>

      <Divider container />

      <Privacy />

      <Divider />

      <Wrapper background={COLOR.WHITE}>
        <Questions />
        <Divider container />
        <Wrapper container containerPaddingBottom={0}>
          <FrequentlyAskedQuestions
            title={faqSection.title}
            questions={faqSection.questions}
            relatedGuide={faqSection.relatedGuide}
            selectedProduct={PRODUCTS.WILLS}
          />
        </Wrapper>

        <Guides
          articles={GUIDES_COPY.articles}
          title={GUIDES_COPY.title}
          readMore={GUIDES_COPY.readMore}
          freeGuide={GUIDES_COPY.freeGuide}
        />

        <Wrapper container containerPaddingTop={0}>
          <Charities />
        </Wrapper>
      </Wrapper>

      <GetStarted
        partnerCampaignReferral
        telephoneWillsEnabled={partner.telephoneWillsEnabled}
      />
    </PartnerLandingPageLayout>
  )
}

export const query = graphql`
  query PartnerOnlineAndTelephone($slug: String!) {
    page: contentfulPartnerCampaignPage(slug: { eq: $slug }) {
      ...CoBrandedBannerFields
      utmCampaign
      utmMedium
      heroTitle
      heroSubtitle {
        heroSubtitle
      }
      heroCtaText
      partner {
        utmSource
        utmChannel
        telephoneWillsEnabled
        hideFarewillLogoInHeader
        partnerId
        offersDiscountedWills
        partnerCustomMessage {
          partnerCustomMessage
        }
        partnerDisplayName
      }
    }
    faqSection: contentfulFaqSection(entryTitle: { eq: "Wills" }) {
      ...FaqSectionFields
    }
  }
`

export default PartnerOnlineAndTelephone
